import React/*, { useState, useEffect }*/ from 'react';
// import { useHistory } from 'react-router-dom';

import * as S from './styles';

function Chronometer({ onTimeout }) {
  // const [time, setTime] = useState(300);
  // const history = useHistory();

  // useEffect(() => {
  //   let timer = null;
  //   timer = setInterval(() => {
  //     setTime(old => old - 1);
  //   }, 1000);
  // }, [])

  // const minutes = Math.floor(time / 60) < 10 ? '0' + Math.floor(time / 60) : Math.floor(time / 60);
  // const seconds = time % 60 < 10 ? '0' + time % 60 : time % 60;

  // if (time === 0) {
  //   onTimeout();
  //   history.go(0);
  // }
  return (
    <S.Container>
      <p className="normal"><S.BsClockHistorys />05:00</p>
      {/* { time > 60 &&
        <p className="normal"><S.BsClockHistorys />{`${minutes}:${seconds}`}</p>
      }
      { time <= 60 &&
        <p className="red"><S.BsClockHistorys />{`${minutes}:${seconds}`}</p>
      } */}
    </S.Container>
  );
}

export default Chronometer;