import styled from 'styled-components';

export const Textimage = styled.div`
  img{
    float: right;
    width: 50%;
    margin-left: 10px;
  }

  margin-bottom: 10px;
`;
