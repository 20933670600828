import React from 'react';

import * as S from './styles';

function Points({points}) {


  return (
    <S.Container>
        <p>100<S.GrGamepads /></p>
    </S.Container>
  );
}

export default Points;