import React,{ useState} from 'react';
import { useHistory, Link } from 'react-router-dom';

import Container from '../../components/Container';
import Header from '../../components/Header';
import Content from '../../components/Content';
import Title from '../../components/Title';
import Paragraph from '../../components/Paragraph';
import TextGreen from '../../components/TextGreen';
import CircularButton from '../../components/CircularButton';
import Button from '../../components/Button';

import Modal from '../../components/Modal';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import * as S from './styles';

import ilustra1 from '../../assets/07.png';

function Pageseven() {
  const history = useHistory();
  
  const [modal, setModal] = useState(false);

  function pageBack(){
    history.push('/6');
  }

  function pageNext(){
    history.push('/8');
  }
  return (
    <>
      <Modal open={modal} setOpen={() => setModal(!modal)} >
        <Title style={{marginBottom: '20px'}}>Caixa Econômica Federal:</Title>Banco responsável pelo pagamento do benefício.
      </Modal>
      <Container>
        <CircularButton onClick={pageBack}><IoIosArrowBack /></CircularButton>
        <Content>
        <Header>CAÇA-PALAVRA</Header>
            <Paragraph italic>Procure e marque, no diagrama de letras, as palavras em destaque no texto.</Paragraph>
            <Title>Bancos para retirada do benefício</Title>
            <Paragraph>A seguir, a relação de bancos nos quais o cidadão inscrito poderá fazer o saque do seu benefício.</Paragraph>
            <S.Textimage>
              <ul>
                <li><span><TextGreen>BANCO</TextGreen> do Brasil</span></li>
                <li><span><TextGreen style={{cursor: "pointer"}} onClick={() => setModal(true)}>CAIXA ECONÔMICA FEDERAL</TextGreen></span></li>
                <li><span>Banco da <TextGreen>AMAZÔNIA</TextGreen></span></li>
                <li><span>Banco do <TextGreen>NORDESTE</TextGreen></span></li>
              </ul>
              <S.Image><img src={ilustra1} alt="Ilustração 1" /></S.Image>
            </S.Textimage>
            <S.Button><Link to="/cacapalavra/2"><Button>Jogar!</Button></Link></S.Button>
        </Content>
        <CircularButton onClick={pageNext}><IoIosArrowForward /></CircularButton>
  </Container>
    </>
  );
}

export default Pageseven;