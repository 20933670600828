import React from 'react';

import * as S from './styles';

function WordTile({ letter, selected=false, finished=false, onClick, translate }) {
  return (
    <S.Container selected={selected} onClick={onClick} finished={finished}>
      {letter}
    </S.Container>
  );
}

export default WordTile;