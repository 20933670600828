import styled from 'styled-components';

export const TextImage = styled.div`
  margin: 10px auto;

  img {
    width: 200px;
    float: right;
    margin-left: 10px;
  }
`

export const Image2 = styled.div`
  margin: 15px auto 0;

  img {
    height: 118px;
  }
`

export const List = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  li{
    margin: 0 2px;
  }
`

export const Image3 = styled.div`
  margin: 20px auto 0;

  img {
    height: 118px;
  }
`