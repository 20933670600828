import React, { useState/*, useEffect*/ } from 'react';
import { useHistory } from 'react-router-dom';

import Container from '../../components/Container';
import Header from '../../components/Header';
import Content from '../../components/Content';
import Paragraph from '../../components/Paragraph';
import SelectImage from '../../components/SelectImage';
import CircularButton from '../../components/CircularButton';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import TextGreen from '../../components/TextGreen';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import Video from '../../assets/videos/encaixe.mp4';

import * as S from './styles';

import ilustra1 from '../../assets/06.png';
import al from '../../assets/al.png';
import ca from '../../assets/ca.png';
import di from '../../assets/di.png';
import so from '../../assets/so.png';
import pan from '../../assets/pan.png';
import gi from '../../assets/gi.png';
import pou from '../../assets/pou.png';
import tal from '../../assets/tal.png';
import ci from '../../assets/ci.png';

function Pagesix() {
  const [selected, setSelected] = useState();
  const [win, setWin] = useState([]);
  const [active, setActive] = useState(false);
  const [err, setErr] = useState();

  const [modal, setModal] = useState(false);

  const [points, setPoints] = useState(0);
  // useEffect(() => {
  //   setPoints(sessionStorage.getItem('pointsUser'));
  // }, [])

  // useEffect(() => {
  //   sessionStorage.setItem('pointsUser', points);
  // }, [points]);

  // const updatePoints = () => {
  //   let curr_points = sessionStorage.getItem('pointsUser')

  //   if (curr_points > 0) {
  //     setPoints(curr_points - 20);
  //   }
  // }

  const history = useHistory();

  function pageBack() {
    history.push('/5');
  }

  function pageNext() {
    history.push('/7');
  }

  const select = (id) => {
    if (win.includes(id)) {
      return;
    }

    if (selected === id) {
      setSelected(null);
      return;
    }
    setSelected(id);
  }

  const verify = (id) => {
    if (win.includes(id)) {
      return;
    }

    if (id === selected) {
      setWin(old => old.concat([selected]));
      setSelected(null);
    } else {
      setErr(id);
      // let curr_points = sessionStorage.getItem('pointsUser');

      // if (curr_points >= 5) {
      //   setPoints(curr_points - 5);
      // } else {
      //   setPoints(0);
      //   history.push('/');
      // }
    }
  }

  return (
    <>
      <Modal open={modal} setOpen={() => setModal(!modal)} >
        <Title style={{ marginBottom: '20px' }}>Conta de tipo especial:</Title>Conta poupança exclusiva para o depósito.
      </Modal>
      <Container>
        <CircularButton onClick={pageBack}><IoIosArrowBack /></CircularButton>
        <Content>
          <Header chronometer points={points} /*onTimeout={() => updatePoints()}*/>ENCAIXE</Header>
          <Paragraph>Com relação ao pagamento, o crédito do auxílio emergencial será efetuado
          por bancos públicos federais, por meio de uma <TextGreen style={{ cursor: "pointer" }} onClick={() => setModal(true)}>conta de tipo especial</TextGreen>, que será
            aberta automaticamente em nome do beneficiário.</Paragraph>
          <Paragraph italic>Encaixe as peças e descubra que tipo de conta é essa.</Paragraph>

          <S.Image><img src={ilustra1} alt="Salão de cabelereiro" /></S.Image>
          <S.Buttons>
            <SelectImage
              src={al}
              onClick={() => select(0)}
              selected={selected === 0}
              win={win.includes(0)}
            />
            <SelectImage
              src={ca}
              onClick={() => select(1)}
              selected={selected === 1}
              win={win.includes(1)}
            />
            <SelectImage
              src={gi}
              onClick={() => select(2)}
              selected={selected === 2}
              win={win.includes(2)}
            />
            <SelectImage
              src={so}
              onClick={() => select(3)}
              selected={selected === 3}
              win={win.includes(3)}
            />
            <SelectImage
              src={pou}
              onClick={() => select(4)}
              selected={selected === 4}
              win={win.includes(4)}
            />
          </S.Buttons>

          <S.Buttons>
            <SelectImage
              src={di}
              onClick={() => select(5)}
              selected={selected === 5}
              win={win.includes(5)}
            />
            <SelectImage
              src={pan}
              onClick={() => select(6)}
              selected={selected === 6}
              win={win.includes(6)}
            />
            <SelectImage
              src={tal}
              onClick={() => select(7)}
              selected={selected === 7}
              win={win.includes(7)}
            />
            <SelectImage
              src={ci}
              onClick={() => select(8)}
              selected={selected === 8}
              win={win.includes(8)}
            />
          </S.Buttons>

          <S.Retangles>
            <S.SameColor>
              <S.Retangle
                color="#00a8ec"
                onClick={() => verify(4)}
                win={win.includes(4)}
                err={err === 4}
              >
                <img className='win-image' src={pou} alt='Trecho da palavra Poupança' />
              </S.Retangle>

              <S.Retangle
                color="#00a8ec"
                onClick={() => verify(6)}
                win={win.includes(6)}
                err={err === 6}
              >
                <img className='win-image' src={pan} alt='Trecho da palavra Poupança' />
              </S.Retangle>

              <S.Retangle
                color="#00a8ec"
                onClick={() => verify(1)}
                win={win.includes(1)}
                err={err === 1}
              >
                <img className='win-image' src={ca} alt='Trecho da palavra Poupança' />
              </S.Retangle>
            </S.SameColor>
          </S.Retangles>
          <S.Retangles>
            <S.SameColor>
              <S.Retangle
                color="#e3007b"
                onClick={() => verify(3)}
                win={win.includes(3)}
                err={err === 3}
              >
                <img className='win-image' src={so} alt='Trecho da palavra Social'/>
              </S.Retangle>
              <S.Retangle
                color="#e3007b"
                onClick={() => verify(8)}
                win={win.includes(8)}
                err={err === 8}
              >
                <img className='win-image' src={ci} alt='Trecho da palavra Social'/>
              </S.Retangle>
              <S.Retangle
                color="#e3007b"
                onClick={() => verify(0)}
                win={win.includes(0)}
                err={err === 0}
              >
                <img className='win-image' src={al} alt='Trecho da palavra Social'/>
              </S.Retangle>
            </S.SameColor>
          </S.Retangles>
          <S.Retangles>
            <S.SameColor>
              <S.Retangle
                color="#009f3c"
                onClick={() => verify(5)}
                win={win.includes(5)}
                err={err === 5}
              >
                <img className='win-image' src={di} alt='Trecho da palavra Digital'/>
              </S.Retangle>
              <S.Retangle
                color="#009f3c"
                onClick={() => verify(2)}
                win={win.includes(2)}
                err={err === 2}
              >
                <img className='win-image' src={gi} alt='Trecho da palavra Digital'/>
              </S.Retangle>
              <S.Retangle
                color="#009f3c"
                onClick={() => verify(7)}
                win={win.includes(7)}
                err={err === 7}
              >
                <img className='win-image' src={tal} alt='Trecho da palavra Digital'/>
              </S.Retangle>
            </S.SameColor>
          </S.Retangles>
          <Button onClick={() => setActive(!active)}>Como Jogar?</Button>
        </Content>
        {
          active === true &&
          <Modal open={active} setOpen={() => setActive(!active)} src={Video} />
        }
        <CircularButton onClick={pageNext}><IoIosArrowForward /></CircularButton>
      </Container>
    </>
  );
}

export default Pagesix;