import React from 'react';

import { Button } from './styles';

function CircularButton({disabled, onClick, children}) {
  return (
    <Button 
      disabled={disabled}
      onClick={onClick}
    >{children}</Button>
  );
}

export default CircularButton;