import React from 'react';
import * as S from './styles';

function Title({children, style}) {
  return (
  <>
    <S.Maintitle style={style}>
      {children}
    </S.Maintitle>
  </>
  );
}

export default Title;