import styled from 'styled-components';

export const Textimage = styled.div`
  img{
    float: left;
    width: 200px;
    height: 200px;
    margin-right: 10px;
  }
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`