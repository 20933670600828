import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Words = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-bottom: 10px;
  text-align: justify;

  p{
    color: #000;
    margin-bottom: 10px;

    span{
      color: ${({theme}) => theme.palette.secondary.main};
      font-weight: bold;
    }
  }
`

export const Buttons = styled.div`
  display: flex;
`