import styled from 'styled-components';

export const Image = styled.div`
  img{
    width: 170px;
  }

  margin: 0 auto;
`


export const List = styled.div`
  display: flex;
  justify-content: center;
  background: #F7F5E6;
  margin-top: 0px;
  padding: 15px;

  ul{
    display: flex;
  }

  li {
    display:flex;
    flex-direction: column;
    align-items: center;

    margin-right: 5px;
  }

  input {
    text-align:center;
    font-weight: 600;
    font-size: 12px;

    width: 23px;
    height: 23px;
    border: 1px solid ${({theme}) => theme.palette.secondary.main};
    border-radius: 20%;

    margin-top: 2px;
  }
`

export const List2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F7F5E6;
  margin: 10px 0 15px;
  padding: 15px 10px 5px 30px;

  div{
    display: flex;
    margin-bottom: 5px;
  }

  ul{
    display: flex;
    margin-right: 10px;
  }

  li {
    display:flex;
    flex-direction: column;
    align-items: center;

    margin-right: 2px;
  }
`