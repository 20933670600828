import React from 'react';
import { useHistory } from 'react-router-dom';

import Container from '../../components/Container';
import Header from '../../components/Header';
import Content from '../../components/Content';
import Paragraph from '../../components/Paragraph';

import CircularButton from '../../components/CircularButton';

import * as S from './styles';

import ilustra1 from '../../assets/02.png';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

function Pageone() {
  const history = useHistory();

  function backPage() {
    history.push('/apresentacao');
  }

  function nextPage() {
    history.push('/2');
  }

  return (
    <Container>
      <CircularButton onClick={backPage}><IoIosArrowBack /></CircularButton>
      <Content>
        <Header>APRESENTAÇÃO</Header>
        <Paragraph colors>Com o avanço da Covid-19, fez-se necessário o aumento do isolamento social, com o fechamento de comércio e de serviços considerados não essenciais. Essa é a prática mais acertada e eficiente para tentar impedir a proliferação do vírus, achatando assim a curva de contaminação. Com essa medida, muitos trabalhadores informais ficaram sem renda ou qualquer forma de conseguir um sustento para atravessar a crise.</Paragraph>
        <Paragraph colors>Com o objetivo de socorrer essa parte da população, o Governo Federal criou o auxílio emergencial: uma renda básica concedida por três meses para pessoas que ficaram sem rendimentos em razão da pandemia.</Paragraph>
        <Paragraph colors>Nos passatempos desta revista você saberá mais sobre o auxílio, quem tem direito a ele e como consegui-lo.</Paragraph>

        <S.Image><img src={ilustra1} alt="Ilustração 1" /></S.Image>
      </Content>
      <CircularButton onClick={nextPage}><IoIosArrowForward /></CircularButton>
    </Container>
  );
}

export default Pageone;