import styled from 'styled-components';
import {GrGamepad} from 'react-icons/gr';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60px;
  height: 25px;
  border-radius: 5%;
  background-color: #fff;
  p {
    padding: 5px;
    display: flex;
    color: ${({theme}) => theme.palette.secondary.main};
  }
  p.red {
    color: red;
  }
`;

export const GrGamepads = styled(GrGamepad)`
  margin-top: 3px;
  margin-left: 5px;
`