const theme = {
  palette: {
    primary: {
      light: "#ffffff",
      main: "#eeeeee",
      dark: "#bcbcbc"
    },
    secondary: {
      light: "#3b8071",
      main: "#005346",
      dark: "#00291f",
      contrastText: "#fff",
    }
  }
};

export default theme;