import styled from 'styled-components';
import capacoquetel from '../../assets/capa-coquetel.png';

export const ColorBg = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-image: url(${capacoquetel});
    background-clip: content-box;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    height: 560px;
`
