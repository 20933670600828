import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Container from '../../components/Container';
import Header from '../../components/Header';
import Content from '../../components/Content';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import TextThrough from '../../components/TextThrough';

import CacaPalavras from '../../components/CacaPalavras';

import Video from '../../assets/videos/cacapalavras.mp4';

import * as S from './styles';

import wordsearch from 'wordsearch';

const words = [
  'banco',
  'caixa',
  'economica',
  'federal',
  'amazonia',
  'nordeste',
];

function Cacapalavra2() {
  const [search, setSearch] = useState({ grid: [] });
  const [active, setActive] = useState(false);
  const [points, setPoints] = useState(0);
  const [winWords, setWinWords] = useState([]);
  
  useEffect(() => {
    setSearch(wordsearch(words, 20, 14, { backwards: 0 }));
    // setPoints(sessionStorage.getItem('pointsUser'));
  }, []);

  // useEffect(() => {
  //   sessionStorage.setItem('pointsUser', points);
  // }, [points]);

  // const updatePoints = () => {
  //   let curr_points = sessionStorage.getItem('pointsUser')

  //   if(curr_points > 0){
  //     setPoints(curr_points - 20);
  //   }
  // }

  const renderWord = (word, real) => {
    if(winWords.indexOf(word) !== -1){
      return (
        <TextThrough>{real}</TextThrough>
      )
    }else{
      return real
    }
  }
  return (
    <>
      <Container>
        <Content>
          <S.Container>
            <Header chronometer /*onTimeout={() => updatePoints()}*/ points={points}>CAÇA-PALAVRAS</Header>
            <S.Words>
              <p>Palavras: <span>{renderWord("banco", "Banco")}, {renderWord("caixa", "Caixa")}, {renderWord("economica", "Ecônomica")}, {renderWord("federal", "Federal")}, {renderWord("amazonia", "Amazônia")}, {renderWord("nordeste", "Nordeste")}</span>.</p>
              <CacaPalavras matriz={search.grid} words={words} onSelect={setWinWords}/>
            </S.Words>
            <S.Buttons>
              <Link to="/7"><Button>Voltar</Button></Link>
              <Button last onClick={() => setActive(!active)}>Como Jogar?</Button>
            </S.Buttons>
          </S.Container>
        </Content>
        {
          active === true &&
          <Modal open={active} setOpen={() => setActive(!active)} src={Video}/>
        }
      </Container>
    </>
  );
}

export default Cacapalavra2;