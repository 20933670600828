import React from 'react';

import * as S from './styles';

function Button({onClick, children, last}) {
  return (
    <S.Button 
      last={last}
      onClick={onClick}
    >{children}</S.Button>
  );
}

export default Button;