import styled from 'styled-components';

export const Image = styled.div`
  img{
    width: 200px;
  }

  margin: 10px auto;
`;

export const Button = styled.div`
  margin: 10px auto;
`;

export const Textimage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  ul{
    display: flex;
    flex-direction: column;
    align-items: baseline;

    background-color: #F7F5E6;
    width: 100%;

    padding: 15px;
  }

  li {
    display: flex;
    font-size: 12px;
    color: #000;
  }
`