import React from 'react';
import Chronometer from '../Chronometer';
import Points from '../Points';
import * as S from './styles';

function Header({children, chronometer, onTimeout, points}) {
  return (
  <>
    <S.Mainheader points={points} chronometer={chronometer}>
      { points>=0 &&
        <Points points={points}/>
      }
      {children}
      { chronometer &&
        <Chronometer onTimeout={onTimeout} />
      }
    </S.Mainheader>
  </>
  );
}

export default Header;