import styled from 'styled-components';

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  margin: 0 auto;
  width: 90%;
  height: 500px;

  p{
    color: #000;
    margin-bottom: 10px;

    span{
      color: ${({theme}) => theme.palette.secondary.main};
      font-weight: bold;
    }
  }
`

export const Game = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 90%;
  height: 500px;
`

export const Rows = styled.div`
  display: flex;

  div{
    width: 23px;
    height: 23px;
  } 
`

// 