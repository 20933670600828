import styled from 'styled-components';
import {GoArrowDown, GoArrowLeft, GoArrowRight, GoArrowUp} from 'react-icons/go';

export const Image = styled.div`
  img{
    width: 300px;
  }

  margin: 10px auto 0;
`;

export const Game = styled.div`
  display: flex;
  margin: 0 auto;

  .circles{
  display: flex;
  flex-direction: column;
  align-items: center;
  }

  span{
    color: #000;
    font-size: 10px;
    font-weight: 300;

    margin-right: 10px;
  }

  .circles div{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .circles div.updown{
    display: flex;
    width: 98%;
    justify-content: space-between;
  }
`

export const Inputgame = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F7F5E6;

  margin: 10px 0 15px;

  padding: 10px;

  ul{
    display: flex;
  }

  li {
    margin: 2px;
  }
`

export const Circle = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 50%;
  font-size: 14px;

  color: #000;

  &.first{
    background: #D9F9F2;
  }
`
export const Down = styled(GoArrowDown)`
  color: #CDBE6A;
`

export const Left = styled(GoArrowLeft)`
  color: #CDBE6A;
`

export const Right = styled(GoArrowRight)`
  color: #CDBE6A;
`

export const Up = styled(GoArrowUp)`
  color: #CDBE6A;
`