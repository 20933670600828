import React, { createRef, useState/*, useEffect*/ } from 'react';
import { Link/*, useHistory*/ } from 'react-router-dom';

import Container from '../../components/Container';
import Header from '../../components/Header';
import Content from '../../components/Content';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import InputGame from '../../components/InputGame';
import TextThrough from '../../components/TextThrough';

import Video from '../../assets/videos/dominox.mp4';


import * as S from './styles';

function Dominox() {
  // const history = useHistory();
  const [active, setActive] = useState(false);
  const [points, setPoints] = useState(0);
  // useEffect(() => {
  //   setPoints(sessionStorage.getItem('pointsUser'));
  // }, [])

  // useEffect(() => {
  //   sessionStorage.setItem('pointsUser', points);
  // }, [points]);

  // const updatePoints = () => {
  //   let curr_points = sessionStorage.getItem('pointsUser')

  //   if (curr_points > 0) {
  //     setPoints(curr_points - 20);
  //   }
  // }

  const input2Ref = createRef();
  const input3Ref = createRef();
  const input4Ref = createRef();
  const input5Ref = createRef();
  const input6Ref = createRef();
  const input7Ref = createRef();
  const input8Ref = createRef();
  const input9Ref = createRef();
  const input10Ref = createRef();
  const input11Ref = createRef();
  const input12Ref = createRef();
  const input13Ref = createRef();
  const input14Ref = createRef();
  const input15Ref = createRef();
  const input16Ref = createRef();
  const input17Ref = createRef();
  const input18Ref = createRef();
  const input19Ref = createRef();
  const input20Ref = createRef();
  const input21Ref = createRef();
  const input22Ref = createRef();
  const input23Ref = createRef();
  const input24Ref = createRef();
  const input25Ref = createRef();
  const input26Ref = createRef();
  const input27Ref = createRef();
  const input28Ref = createRef();
  const input29Ref = createRef();
  const input30Ref = createRef();
  const input31Ref = createRef();
  const input32Ref = createRef();
  const input33Ref = createRef();
  const input34Ref = createRef();
  const input35Ref = createRef();
  const input36Ref = createRef();
  const input37Ref = createRef();
  const input38Ref = createRef();
  const input39Ref = createRef();
  const input40Ref = createRef();
  const input41Ref = createRef();
  const input42Ref = createRef();
  const input43Ref = createRef();
  const input44Ref = createRef();
  const input45Ref = createRef();
  const input46Ref = createRef();
  const input47Ref = createRef();
  const input48Ref = createRef();
  const input49Ref = createRef();
  const input50Ref = createRef();
  const input51Ref = createRef();
  const input52Ref = createRef();
  const input53Ref = createRef();
  const input54Ref = createRef();
  const input55Ref = createRef();
  const input56Ref = createRef();
  const input57Ref = createRef();

  const RESULT1 = "pagamento";
  const [word, setWord] = useState("         ");

  const RESULT2 = "preencher";
  const [word2, setWord2] = useState("         ");

  const RESULT3 = "numero";
  const [word3, setWord3] = useState("      ");

  const RESULT4 = "plataforma";
  const [word4, setWord4] = useState("          ");

  const RESULT5 = "cadastro";
  const [word5, setWord5] = useState("        ");

  const RESULT6 = "etapas";
  const [word6, setWord6] = useState("      ");

  const RESULT7 = "processados";
  const [word7, setWord7] = useState("           ");

  const RESULT8 = "trabalhadores";
  const [word8, setWord8] = useState("             ");

  function validate(idx, ref, event) {
    if (event.target.value.length <= 0 || !ref.current) {
      return
    }
    setWord(word.substring(0, idx) + event.target.value + word.substring(idx + 1))
    ref.current.focus();

    // if (RESULT1[idx] !== event.target.value.toLowerCase()) {
    //   let curr_points = sessionStorage.getItem('pointsUser');

    //   if (curr_points >= 5) {
    //     setPoints(curr_points - 5);
    //   } else {
    //     setPoints(0);
    //     history.push('/');
    //   }
    // }
  }

  function validate2(idx, ref, event) {
    if (event.target.value.length <= 0 || !ref.current) {
      return
    }
    setWord2(word2.substring(0, idx) + event.target.value + word2.substring(idx + 1))
    ref.current.focus();

    // if (RESULT2[idx] !== event.target.value.toLowerCase()) {
    //   let curr_points = sessionStorage.getItem('pointsUser');

    //   if (curr_points >= 5) {
    //     setPoints(curr_points - 5);
    //   } else {
    //     setPoints(0);
    //     history.push('/');
    //   }
    // }
  }

  function validate3(idx, ref, event) {
    if (event.target.value.length <= 0 || !ref.current) {
      return
    }
    setWord3(word3.substring(0, idx) + event.target.value + word3.substring(idx + 1))
    ref.current.focus();

    // if (RESULT3[idx] !== event.target.value.toLowerCase()) {
    //   let curr_points = sessionStorage.getItem('pointsUser');

    //   if (curr_points >= 5) {
    //     setPoints(curr_points - 5);
    //   } else {
    //     setPoints(0);
    //     history.push('/');
    //   }
    // }
  }

  function validate4(idx, ref, event) {
    if (event.target.value.length <= 0 || !ref.current) {
      return
    }
    setWord4(word4.substring(0, idx) + event.target.value + word4.substring(idx + 1))
    ref.current.focus();

    // if (RESULT4[idx] !== event.target.value.toLowerCase()) {
    //   let curr_points = sessionStorage.getItem('pointsUser');

    //   if (curr_points >= 5) {
    //     setPoints(curr_points - 5);
    //   } else {
    //     setPoints(0);
    //     history.push('/');
    //   }
    // }
  }

  function validate5(idx, ref, event) {
    if (event.target.value.length <= 0 || !ref.current) {
      return
    }
    setWord5(word5.substring(0, idx) + event.target.value + word5.substring(idx + 1))
    ref.current.focus();

    // if (RESULT5[idx] !== event.target.value.toLowerCase()) {
    //   let curr_points = sessionStorage.getItem('pointsUser');

    //   if (curr_points >= 5) {
    //     setPoints(curr_points - 5);
    //   } else {
    //     setPoints(0);
    //     history.push('/');
    //   }
    // }
  }

  function validate6(idx, ref, event) {
    if (event.target.value.length <= 0 || !ref.current) {
      return
    }
    setWord6(word6.substring(0, idx) + event.target.value + word6.substring(idx + 1))
    ref.current.focus();

    // if (RESULT6[idx] !== event.target.value.toLowerCase()) {
    //   let curr_points = sessionStorage.getItem('pointsUser');

    //   if (curr_points >= 5) {
    //     setPoints(curr_points - 5);
    //   } else {
    //     setPoints(0);
    //     history.push('/');
    //   }
    // }
  }

  function validate7(idx, ref, event) {
    if (event.target.value.length <= 0 || !ref.current) {
      return
    }
    setWord7(word7.substring(0, idx) + event.target.value + word7.substring(idx + 1))
    ref.current.focus();

    // if (RESULT7[idx] !== event.target.value.toLowerCase()) {
    //   let curr_points = sessionStorage.getItem('pointsUser');

    //   if (curr_points >= 5) {
    //     setPoints(curr_points - 5);
    //   } else {
    //     setPoints(0);
    //     history.push('/');
    //   }
    // }
  }

  function validate8(idx, ref, event) {
    if (event.target.value.length <= 0 || !ref.current) {
      return
    }
    setWord8(word8.substring(0, idx) + event.target.value + word8.substring(idx + 1))
    ref.current.focus();

    // if (RESULT8[idx] !== event.target.value.toLowerCase()) {
    //   let curr_points = sessionStorage.getItem('pointsUser');

    //   if (curr_points >= 5) {
    //     setPoints(curr_points - 5);
    //   } else {
    //     setPoints(0);
    //     history.push('/');
    //   }
    // }
  }

  return (
    <>
      <Container>
        <Content>
          <Header chronometer points={points} /*onTimeout={() => updatePoints()}*/>DOMINOX</Header>
          <S.Container>
            <p>Palavras: <span>Trabalhadores, Cadastro, <TextThrough>Benefício</TextThrough>, Preencher, Plataforma, Processados, Número, Pagamento, Etapas</span>.</p>
            <S.Game>
              <S.Rows>
                <div></div>
                <div></div>
                <InputGame
                  //P de Pagamento
                  success={word[0] === " " || word[0] === "" ? null : RESULT1[0] === word[0].toLowerCase()}
                  onChange={(event) => {
                    validate(0, input2Ref, event);
                  }}
                />
              </S.Rows>

              <S.Rows>
                <div></div>
                <div></div>
                <InputGame
                  //A de pAgamento
                  ref={input2Ref}
                  success={word[1] === " " || word[1] === "" ? null : RESULT1[1] === word[1].toLowerCase()}
                  onChange={(event) => {
                    validate(1, input3Ref, event);
                  }}
                />
                <div></div>
                <InputGame
                  //P de Preencher
                  success={word2[0] === " " || word2[0] === "" ? null : RESULT2[0] === word2[0].toLowerCase()}
                  onChange={(event) => {
                    validate2(0, input9Ref, event);
                  }}
                />
                <InputGame
                  //R de pReencher
                  ref={input9Ref}
                  success={word2[1] === " " || word2[1] === "" ? null : RESULT2[1] === word2[1].toLowerCase()}
                  onChange={(event) => {
                    validate2(1, input10Ref, event);
                  }}
                />
                <InputGame
                  //E de prEencher
                  ref={input10Ref}
                  success={word2[2] === " " || word2[2] === "" ? null : RESULT2[2] === word2[2].toLowerCase()}
                  onChange={(event) => {
                    validate2(2, input11Ref, event);
                  }}
                />
                <InputGame
                  //E de preEncher
                  ref={input11Ref}
                  success={word2[3] === " " || word2[3] === "" ? null : RESULT2[3] === word2[3].toLowerCase()}
                  onChange={(event) => {
                    validate2(3, input13Ref, event);
                  }}
                />
                <InputGame
                  //N de preeNcher
                  ref={input12Ref}
                  success={word2[4] === " " || word2[4] === "" ? null : RESULT2[4] === word2[4].toLowerCase()}
                  onChange={(event) => {
                    validate2(4, input17Ref, event);
                  }}
                />
                <InputGame
                  //C de preenCher
                  ref={input13Ref}
                  success={word2[5] === " " || word2[5] === "" ? null : RESULT2[5] === word2[5].toLowerCase()}
                  onChange={(event) => {
                    validate2(5, input14Ref, event);
                  }}
                />
                <InputGame
                  //H de preencHer
                  ref={input14Ref}
                  success={word2[6] === " " || word2[6] === "" ? null : RESULT2[6] === word2[6].toLowerCase()}
                  onChange={(event) => {
                    validate2(6, input15Ref, event);
                  }}
                />
                <InputGame
                  //E de preenchEr
                  ref={input15Ref}
                  success={word2[7] === " " || word2[7] === "" ? null : RESULT2[7] === word2[7].toLowerCase()}
                  onChange={(event) => {
                    validate2(7, input16Ref, event);
                  }}
                />
                <InputGame
                  //R de preencheR
                  ref={input16Ref}
                  success={word2[8] === " " || word2[8] === "" ? null : RESULT2[8] === word2[8].toLowerCase()}
                  onChange={(event) => {
                    validate2(8, input16Ref, event);
                  }}
                />
              </S.Rows>

              <S.Rows>
                <div></div>
                <div></div>
                <InputGame
                  //G de paGamento
                  ref={input3Ref}
                  success={word[2] === " " || word[2] === "" ? null : RESULT1[2] === word[2].toLowerCase()}
                  onChange={(event) => {
                    validate(2, input4Ref, event);
                  }}
                />
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <InputGame
                  //U de nUmero
                  ref={input17Ref}
                  success={word3[1] === " " || word3[1] === "" ? null : RESULT3[1] === word3[1].toLowerCase()}
                  onChange={(event) => {
                    validate3(1, input18Ref, event);
                  }}
                />
              </S.Rows>

              <S.Rows>
                <InputGame
                  //P de Plataforma
                  success={word4[0] === " " || word4[0] === "" ? null : RESULT4[0] === word4[0].toLowerCase()}
                  onChange={(event) => {
                    validate4(0, input21Ref, event);
                  }}
                />
                <InputGame
                  //L de pLataforma
                  ref={input21Ref}
                  success={word4[1] === " " || word4[1] === "" ? null : RESULT4[1] === word4[1].toLowerCase()}
                  onChange={(event) => {
                    validate4(1, input22Ref, event);
                  }}
                />
                <InputGame
                  //A de pagAmento
                  ref={input4Ref}
                  success={word[2] === " " || word[2] === "" ? null : RESULT1[2] === word[2].toLowerCase()}
                  onChange={(event) => {
                    validate4(2, input5Ref, event);
                  }}
                />
                <InputGame
                  //T de plaTaforma
                  ref={input22Ref}
                  success={word4[3] === " " || word4[3] === "" ? null : RESULT4[3] === word4[3].toLowerCase()}
                  onChange={(event) => {
                    validate4(3, input23Ref, event);
                  }}
                />
                <InputGame
                  //A de platAforma
                  ref={input23Ref}
                  success={word4[4] === " " || word4[4] === "" ? null : RESULT4[4] === word4[4].toLowerCase()}
                  onChange={(event) => {
                    validate4(4, input24Ref, event);
                  }}
                />
                <InputGame
                  //F de plataForma
                  ref={input24Ref}
                  success={word4[5] === " " || word4[5] === "" ? null : RESULT4[5] === word4[5].toLowerCase()}
                  onChange={(event) => {
                    validate4(5, input25Ref, event);
                  }}
                />
                <InputGame
                  //O de platafOrma
                  ref={input25Ref}
                  success={word4[6] === " " || word4[6] === "" ? null : RESULT4[6] === word4[6].toLowerCase()}
                  onChange={(event) => {
                    validate4(6, input26Ref, event);
                  }}
                />
                <InputGame
                  //R de platafoRma
                  ref={input26Ref}
                  success={word4[7] === " " || word4[7] === "" ? null : RESULT4[7] === word4[7].toLowerCase()}
                  onChange={(event) => {
                    validate4(7, input27Ref, event);
                  }}
                />
                <InputGame
                  ref={input18Ref}
                  //M de nuMero
                  success={word3[2] === " " || word3[2] === "" ? null : RESULT3[2] === word3[2].toLowerCase()}
                  onChange={(event) => {
                    validate3(2, input19Ref, event);
                  }}
                />
                <InputGame
                  //A de plataformA
                  ref={input27Ref}
                  success={word4[9] === " " || word4[9] === "" ? null : RESULT4[9] === word4[9].toLowerCase()}
                  onChange={(event) => {
                    validate4(9, input27Ref, event);
                  }}
                />
              </S.Rows>

              <S.Rows>
                <div></div>
                <div></div>
                <InputGame
                  //M de pagaMento
                  ref={input5Ref}
                  success={word[4] === " " || word[4] === "" ? null : RESULT1[4] === word[4].toLowerCase()}
                  onChange={(event) => {
                    validate(4, input6Ref, event);
                  }}
                />
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <InputGame
                  //E de numEro
                  ref={input19Ref}
                  success={word3[3] === " " || word3[3] === "" ? null : RESULT3[3] === word3[3].toLowerCase()}
                  onChange={(event) => {
                    validate3(3, input20Ref, event);
                  }}
                />
                <div></div>
                <InputGame
                  //C de Cadastro
                  success={word5[0] === " " || word5[0] === "" ? null : RESULT5[0] === word5[0].toLowerCase()}
                  onChange={(event) => {
                    validate5(0, input28Ref, event);
                  }}
                />
              </S.Rows>

              <S.Rows>
                <div></div>
                <div></div>
                <InputGame
                  ref={input6Ref}
                  success={word[5] === " " || word[5] === "" ? null : RESULT1[5] === word[5].toLowerCase()}
                  onChange={(event) => {
                    validate(5, input7Ref, event);
                  }}
                />
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <InputGame
                  //R de numeRo
                  ref={input20Ref}
                  success={word3[4] === " " || word3[4] === "" ? null : RESULT3[4] === word3[4].toLowerCase()}
                  onChange={(event) => {
                    validate3(4, input20Ref, event);
                  }}
                />
                <div></div>
                <InputGame
                  //A de cAdastro
                  ref={input28Ref}
                  success={word5[1] === " " || word5[1] === "" ? null : RESULT5[1] === word5[1].toLowerCase()}
                  onChange={(event) => {
                    validate5(1, input29Ref, event);
                  }}
                />
                <div></div>
                <InputGame
                  //E de Etapas
                  success={word6[0] === " " || word6[0] === "" ? null : RESULT6[0] === word6[0].toLowerCase()}
                  onChange={(event) => {
                    validate6(0, input35Ref, event);
                  }}
                />
              </S.Rows>

              <S.Rows>
                <InputGame value='B' disabled success />
                <InputGame value='E' disabled success />
                <InputGame value='N' disabled success />
                <InputGame value='E' disabled success />
                <InputGame value='F' disabled success />
                <InputGame value='Í' disabled success />
                <InputGame value='C' disabled success />
                <InputGame value='I' disabled success />
                <InputGame value='O' disabled success />
                <div></div>
                <InputGame
                  //D de caDastro
                  ref={input29Ref}
                  success={word5[2] === " " || word5[2] === "" ? null : RESULT5[2] === word5[2].toLowerCase()}
                  onChange={(event) => {
                    validate5(2, input30Ref, event);
                  }}
                />
                <div></div>
                <InputGame
                  //T de eTapas
                  ref={input35Ref}
                  success={word6[1] === " " || word6[1] === "" ? null : RESULT6[1] === word6[1].toLowerCase()}
                  onChange={(event) => {
                    validate6(1, input36Ref, event);
                  }}
                />
              </S.Rows>

              <S.Rows>
                <div></div>
                <div></div>
                <InputGame
                  //T de pagamenTo
                  ref={input7Ref}
                  success={word[7] === " " || word[7] === "" ? null : RESULT1[7] === word[7].toLowerCase()}
                  onChange={(event) => {
                    validate(7, input8Ref, event);
                  }}
                />
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <InputGame
                  //A de cadAstro
                  ref={input30Ref}
                  success={word5[3] === " " || word5[3] === "" ? null : RESULT5[3] === word5[3].toLowerCase()}
                  onChange={(event) => {
                    validate5(3, input32Ref, event);
                  }}
                />
                <div></div>
                <InputGame
                  //A de etApas
                  ref={input36Ref}
                  success={word6[2] === " " || word6[2] === "" ? null : RESULT6[2] === word6[2].toLowerCase()}
                  onChange={(event) => {
                    validate6(2, input37Ref, event);
                  }}
                />
              </S.Rows>

              <S.Rows>
                <InputGame
                  //P de Processados
                  success={word7[0] === " " || word7[0] === "" ? null : RESULT7[0] === word7[0].toLowerCase()}
                  onChange={(event) => {
                    validate7(0, input40Ref, event);
                  }}
                />
                <InputGame
                  //R de pRocessados
                  ref={input40Ref}
                  success={word7[1] === " " || word7[1] === "" ? null : RESULT7[1] === word7[1].toLowerCase()}
                  onChange={(event) => {
                    validate7(1, input41Ref, event);
                  }}
                />
                <InputGame
                  //O de pagamentO
                  ref={input8Ref}
                  success={word[8] === " " || word[8] === "" ? null : RESULT1[8] === word[8].toLowerCase()}
                  onChange={(event) => {
                    validate(8, input8Ref, event);
                  }}
                />
                <InputGame
                  //C de proCessados
                  ref={input41Ref}
                  success={word7[3] === " " || word7[3] === "" ? null : RESULT7[3] === word7[3].toLowerCase()}
                  onChange={(event) => {
                    validate7(3, input42Ref, event);
                  }}
                />
                <InputGame
                  //E de procEssados
                  ref={input42Ref}
                  success={word7[4] === " " || word7[4] === "" ? null : RESULT7[4] === word7[4].toLowerCase()}
                  onChange={(event) => {
                    validate7(4, input43Ref, event);
                  }}
                />
                <InputGame
                  //S de proceSsados
                  ref={input43Ref}
                  success={word7[5] === " " || word7[5] === "" ? null : RESULT7[5] === word7[5].toLowerCase()}
                  onChange={(event) => {
                    validate7(5, input44Ref, event);
                  }}
                />
                <InputGame
                  //S de procesSados
                  ref={input44Ref}
                  success={word7[6] === " " || word7[6] === "" ? null : RESULT7[6] === word7[6].toLowerCase()}
                  onChange={(event) => {
                    validate7(6, input45Ref, event);
                  }}
                />
                <InputGame
                  //A de processAdos
                  ref={input45Ref}
                  success={word7[7] === " " || word7[7] === "" ? null : RESULT7[7] === word7[7].toLowerCase()}
                  onChange={(event) => {
                    validate7(7, input46Ref, event);
                  }}
                />
                <InputGame
                  //D de processaDos
                  ref={input46Ref}
                  success={word7[8] === " " || word7[8] === "" ? null : RESULT7[8] === word7[8].toLowerCase()}
                  onChange={(event) => {
                    validate7(8, input47Ref, event);
                  }}
                />
                <InputGame
                  //O de processadOs
                  ref={input47Ref}
                  success={word7[9] === " " || word7[9] === "" ? null : RESULT7[9] === word7[9].toLowerCase()}
                  onChange={(event) => {
                    validate7(9, input31Ref, event);
                  }}
                />
                <InputGame
                  //S de cadaStro
                  ref={input31Ref}
                  success={word5[4] === " " || word5[4] === "" ? null : RESULT5[4] === word5[4].toLowerCase()}
                  onChange={(event) => {
                    validate5(4, input31Ref, event);
                  }}
                />
                <div></div>
                <InputGame
                  //P de etaPas
                  ref={input37Ref}
                  success={word6[3] === " " || word6[3] === "" ? null : RESULT6[3] === word6[3].toLowerCase()}
                  onChange={(event) => {
                    validate6(3, input38Ref, event);
                  }}
                />
              </S.Rows>

              <S.Rows>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <InputGame
                  //T de cadasTro
                  ref={input32Ref}
                  success={word5[5] === " " || word5[5] === "" ? null : RESULT5[5] === word5[5].toLowerCase()}
                  onChange={(event) => {
                    validate5(5, input33Ref, event);
                  }}
                />
                <div></div>
                <InputGame
                  //A de etapAs
                  ref={input38Ref}
                  success={word6[4] === " " || word6[4] === "" ? null : RESULT6[4] === word6[4].toLowerCase()}
                  onChange={(event) => {
                    validate6(4, input39Ref, event);
                  }}
                />
              </S.Rows>

              <S.Rows>
                <InputGame
                  //T de Trabalhadores
                  success={word8[0] === " " || word8[0] === "" ? null : RESULT8[0] === word8[0].toLowerCase()}
                  onChange={(event) => {
                    validate8(0, input48Ref, event);
                  }}
                />
                <InputGame
                  //R de tRabalhadores
                  ref={input48Ref}
                  success={word8[1] === " " || word8[1] === "" ? null : RESULT8[1] === word8[1].toLowerCase()}
                  onChange={(event) => {
                    validate8(1, input49Ref, event);
                  }}
                />
                <InputGame
                  //A de trAbalhadores
                  ref={input49Ref}
                  success={word8[2] === " " || word8[2] === "" ? null : RESULT8[2] === word8[2].toLowerCase()}
                  onChange={(event) => {
                    validate8(2, input50Ref, event);
                  }}
                />
                <InputGame
                  //B de traBalhadores
                  ref={input50Ref}
                  success={word8[3] === " " || word8[3] === "" ? null : RESULT8[3] === word8[3].toLowerCase()}
                  onChange={(event) => {
                    validate8(3, input51Ref, event);
                  }}
                />
                <InputGame
                  //A de trabAlhadores
                  ref={input51Ref}
                  success={word8[4] === " " || word8[4] === "" ? null : RESULT8[4] === word8[4].toLowerCase()}
                  onChange={(event) => {
                    validate8(4, input52Ref, event);
                  }}
                />
                <InputGame
                  //L de trabaLhadores
                  ref={input52Ref}
                  success={word8[5] === " " || word8[5] === "" ? null : RESULT8[5] === word8[5].toLowerCase()}
                  onChange={(event) => {
                    validate8(5, input53Ref, event);
                  }}
                />
                <InputGame
                  //H de trabalHadores
                  ref={input53Ref}
                  success={word8[6] === " " || word8[6] === "" ? null : RESULT8[6] === word8[6].toLowerCase()}
                  onChange={(event) => {
                    validate8(6, input54Ref, event);
                  }}
                />
                <InputGame
                  //A de trabalhAdores
                  ref={input54Ref}
                  success={word8[7] === " " || word8[7] === "" ? null : RESULT8[7] === word8[7].toLowerCase()}
                  onChange={(event) => {
                    validate8(7, input55Ref, event);
                  }}
                />
                <InputGame
                  //D de trabalhaDores
                  ref={input55Ref}
                  success={word8[8] === " " || word8[8] === "" ? null : RESULT8[8] === word8[8].toLowerCase()}
                  onChange={(event) => {
                    validate8(8, input56Ref, event);
                  }}
                />
                <InputGame
                  //O de trabalhadOres
                  ref={input56Ref}
                  success={word8[9] === " " || word8[9] === "" ? null : RESULT8[9] === word8[9].toLowerCase()}
                  onChange={(event) => {
                    validate8(9, input57Ref, event);
                  }}
                />
                <InputGame
                  //R de cadastRo
                  ref={input33Ref}
                  success={word5[6] === " " || word5[6] === "" ? null : RESULT5[6] === word5[6].toLowerCase()}
                  onChange={(event) => {
                    validate5(6, input34Ref, event);
                  }}
                />
                <InputGame
                  //E de trabalhadorEs
                  ref={input57Ref}
                  success={word8[11] === " " || word8[11] === "" ? null : RESULT8[11] === word8[11].toLowerCase()}
                  onChange={(event) => {
                    validate8(11, input39Ref, event);
                  }}
                />
                <InputGame
                  //S de etapaS
                  ref={input39Ref}
                  success={word6[5] === " " || word6[5] === "" ? null : RESULT6[5] === word6[5].toLowerCase()}
                  onChange={(event) => {
                    validate6(5, input39Ref, event);
                  }}
                />
              </S.Rows>

              <S.Rows>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <InputGame
                  //O de cadastrO
                  ref={input34Ref}
                  success={word5[7] === " " || word5[7] === "" ? null : RESULT5[7] === word5[7].toLowerCase()}
                  onChange={(event) => {
                    validate5(7, input34Ref, event);
                  }}
                />
                <div></div>
                <div></div>
              </S.Rows>
            </S.Game>
          </S.Container>
          <S.Buttons>
            <Link to="/9"><Button>Voltar</Button></Link>
            <Button last onClick={() => setActive(!active)}>Como Jogar?</Button>
          </S.Buttons>
        </Content>
        {
          active === true &&
          <Modal open={active} setOpen={() => setActive(!active)} src={Video} />
        }
      </Container>
    </>
  );
}

export default Dominox;