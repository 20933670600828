import React, { useState, createRef/*, useEffect*/ } from 'react';
import { useHistory } from 'react-router-dom';

import Container from '../../components/Container';
import Header from '../../components/Header';
import Content from '../../components/Content';
import Paragraph from '../../components/Paragraph';
import CircularButton from '../../components/CircularButton';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import InputGame from '../../components/InputGame';

import Title from '../../components/Title';
import TextGreen from '../../components/TextGreen';

import Pencil from '../../components/Icons/Pencil';
import Umbrella from '../../components/Icons/Umbrella';
import Airplane from '../../components/Icons/Airplane';
import ArrowG from '../../components/Icons/ArrowG';
import Radioactive from '../../components/Icons/Radioactive';
import Spades from '../../components/Icons/Spades';
import Scissor from '../../components/Icons/Scissor';
import Star from '../../components/Icons/Star';
import Heart from '../../components/Icons/Heart';
import Radio from '../../components/Icons/Radio';
import Home from '../../components/Icons/Home';
import Smile from '../../components/Icons/Smile';

import * as S from './styles';

import ilustra1 from '../../assets/08.png';

import Video from '../../assets/videos/codigosecreto.mp4';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

function Pageeight() {
  const history = useHistory()
  const [active, setActive] = useState(false);
  const [points, setPoints] = useState(0);

  
  const [modal, setModal] = useState(false);

  // useEffect(() => {
  //   setPoints(sessionStorage.getItem('pointsUser'));
  // },[])
  
  // useEffect(() => {
  //   sessionStorage.setItem('pointsUser', points);
  // }, [points]);

  // const updatePoints = () => {
  //   let curr_points = sessionStorage.getItem('pointsUser')

  //   if(curr_points > 0){
  //     setPoints(curr_points - 20);
  //   }
  // }

  function backPage() {
    history.push('/7');
  }

  function nextPage() {
    history.push('/9');
  }

  const input2Ref = createRef();
  const input3Ref = createRef();
  const input4Ref = createRef();
  const input5Ref = createRef();
  const input6Ref = createRef();
  const input7Ref = createRef();
  const input8Ref = createRef();
  const input9Ref = createRef();
  const input10Ref = createRef();
  const input11Ref = createRef();
  const input12Ref = createRef();
  const input13Ref = createRef();
  const input14Ref = createRef();
  const input15Ref = createRef();
  const input16Ref = createRef();
  const input17Ref = createRef();
  const input18Ref = createRef();
  const input19Ref = createRef();
  const input20Ref = createRef();
  const input21Ref = createRef();
  const input22Ref = createRef();
  const input23Ref = createRef();
  const input24Ref = createRef();
  const input25Ref = createRef();
  const input26Ref = createRef();
  const input27Ref = createRef();
  const input28Ref = createRef();
  const input29Ref = createRef();
  const input30Ref = createRef();
  const input31Ref = createRef();
  const input32Ref = createRef();
  const input33Ref = createRef();
  const input34Ref = createRef();
  const input35Ref = createRef();
  const input36Ref = createRef();

  const RESULT = "agenciaslotericasagenciasdoscorreios";
  const [word, setWord] = useState("                                    ");

  function validate(idx, ref, event) {
    if (event.target.value.length <= 0 || !ref.current) {
      return
    }
    setWord(word.substring(0, idx) + event.target.value + word.substring(idx + 1))
    ref.current.focus();

    // if(RESULT[idx] !== event.target.value.toLowerCase()){
    //   let curr_points = sessionStorage.getItem('pointsUser');

    //   if (curr_points >= 5) {
    //     setPoints(curr_points - 5);
    //   } else {
    //     setPoints(0);
    //     history.push('/');
    //   }
    // }
  }

  return (
    <>
      <Modal open={modal} setOpen={() => setModal(!modal)} >
        <Title style={{marginBottom: '20px'}}>Locais p/ Saque:</Title>Locais para retirada do dinheiro.
      </Modal>
      <Container>
        <CircularButton onClick={backPage}><IoIosArrowBack /></CircularButton>
        <Content>
          <Header chronometer points={points} /*onTimeout={() => updatePoints()}*/>CÓDIGO SECRETO</Header>
          <Paragraph>Além dos bancos públicos federais, os saques poderão ser feitos em dois <TextGreen style={{cursor: "pointer"}} onClick={() => setModal(true)}>outros locais</TextGreen>.</Paragraph>
          <Paragraph italic>Substitua os códigos pelas letras correspondentes e descubra que locais são esses.</Paragraph>

          <S.Image><img src={ilustra1} alt="Salão de cabelereiro" /></S.Image>

          <S.List>
            <ul>
              <li><Airplane /><input disabled type="text" value="A" /></li>
              <li><Scissor /><input disabled type="text" value="C" /></li>
              <li><Spades /><input disabled type="text" value="D" /></li>
              <li><Radio /><input disabled type="text" value="E" /></li>
              <li><Heart /><input disabled type="text" value="G" /></li>
              <li><Star /><input disabled type="text" value="I" /></li>
              <li><Radioactive /><input disabled type="text" value="L" /></li>
              <li><Umbrella /><input disabled type="text" value="N" /></li>
              <li><Smile /><input disabled type="text" value="O" /></li>
              <li><Home /><input disabled type="text" value="R" /></li>
              <li><Pencil /><input disabled type="text" value="S" /></li>
              <li><ArrowG /><input disabled type="text" value="T" /></li>
            </ul>
          </S.List>

          <S.List2>
            <div>
              <ul>
                <li>
                  <Airplane />
                  <InputGame
                    success={word[0] === " " || word[0] === "" ? null : RESULT[0] === word[0].toLowerCase()}
                    onChange={(event) => {
                      validate(0, input2Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Heart />
                  <InputGame
                    ref={input2Ref}
                    success={word[1] === " " || word[1] === "" ? null : RESULT[1] === word[1].toLowerCase()}
                    onChange={(event) => {
                      validate(1, input3Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Radio />
                  <InputGame
                    ref={input3Ref}
                    success={word[2] === " " || word[2] === "" ? null : RESULT[2] === word[2].toLowerCase()}
                    onChange={(event) => {
                      validate(2, input4Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Umbrella />
                  <InputGame
                    ref={input4Ref}
                    success={word[3] === " " || word[3] === "" ? null : RESULT[3] === word[3].toLowerCase()}
                    onChange={(event) => {
                      validate(3, input5Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Scissor />
                  <InputGame
                    ref={input5Ref}
                    success={word[4] === " " || word[4] === "" ? null : RESULT[4] === word[4].toLowerCase()}
                    onChange={(event) => {
                      validate(4, input6Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Star />
                  <InputGame
                    ref={input6Ref}
                    success={word[5] === " " || word[5] === "" ? null : RESULT[5] === word[5].toLowerCase()}
                    onChange={(event) => {
                      validate(5, input7Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Airplane />
                  <InputGame
                    ref={input7Ref}
                    success={word[6] === " " || word[6] === "" ? null : RESULT[6] === word[6].toLowerCase()}
                    onChange={(event) => {
                      validate(6, input8Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Pencil />
                  <InputGame
                    ref={input8Ref}
                    success={word[7] === " " || word[7] === "" ? null : RESULT[7] === word[7].toLowerCase()}
                    onChange={(event) => {
                      validate(7, input9Ref, event);
                    }}
                  />
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <Radioactive />
                  <InputGame
                    ref={input9Ref}
                    success={word[8] === " " || word[8] === "" ? null : RESULT[8] === word[8].toLowerCase()}
                    onChange={(event) => {
                      validate(8, input10Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Smile />
                  <InputGame
                    ref={input10Ref}
                    success={word[9] === " " || word[9] === "" ? null : RESULT[9] === word[9].toLowerCase()}
                    onChange={(event) => {
                      validate(9, input11Ref, event);
                    }}
                  />
                </li>
                <li>
                  <ArrowG />
                  <InputGame
                    ref={input11Ref}
                    success={word[10] === " " || word[10] === "" ? null : RESULT[10] === word[10].toLowerCase()}
                    onChange={(event) => {
                      validate(10, input12Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Radio />
                  <InputGame
                    ref={input12Ref}
                    success={word[11] === " " || word[11] === "" ? null : RESULT[11] === word[11].toLowerCase()}
                    onChange={(event) => {
                      validate(11, input13Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Home />
                  <InputGame
                    ref={input13Ref}
                    success={word[12] === " " || word[12] === "" ? null : RESULT[12] === word[12].toLowerCase()}
                    onChange={(event) => {
                      validate(12, input14Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Star />
                  <InputGame
                    ref={input14Ref}
                    success={word[13] === " " || word[13] === "" ? null : RESULT[13] === word[13].toLowerCase()}
                    onChange={(event) => {
                      validate(13, input15Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Scissor />
                  <InputGame
                    ref={input15Ref}
                    success={word[14] === " " || word[14] === "" ? null : RESULT[14] === word[14].toLowerCase()}
                    onChange={(event) => {
                      validate(14, input16Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Airplane />
                  <InputGame
                    ref={input16Ref}
                    success={word[15] === " " || word[15] === "" ? null : RESULT[15] === word[15].toLowerCase()}
                    onChange={(event) => {
                      validate(15, input17Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Pencil />
                  <InputGame
                    ref={input17Ref}
                    success={word[16] === " " || word[16] === "" ? null : RESULT[16] === word[16].toLowerCase()}
                    onChange={(event) => {
                      validate(16, input18Ref, event);
                    }}
                  />
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <Airplane />
                  <InputGame
                    ref={input18Ref}
                    success={word[17] === " " || word[17] === "" ? null : RESULT[17] === word[17].toLowerCase()}
                    onChange={(event) => {
                      validate(17, input19Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Heart />
                  <InputGame
                    ref={input19Ref}
                    success={word[18] === " " || word[18] === "" ? null : RESULT[18] === word[18].toLowerCase()}
                    onChange={(event) => {
                      validate(18, input20Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Radio />
                  <InputGame
                    ref={input20Ref}
                    success={word[19] === " " || word[19] === "" ? null : RESULT[19] === word[19].toLowerCase()}
                    onChange={(event) => {
                      validate(19, input21Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Umbrella />
                  <InputGame
                    ref={input21Ref}
                    success={word[20] === " " || word[20] === "" ? null : RESULT[20] === word[20].toLowerCase()}
                    onChange={(event) => {
                      validate(20, input22Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Scissor />
                  <InputGame
                    ref={input22Ref}
                    success={word[21] === " " || word[21] === "" ? null : RESULT[21] === word[21].toLowerCase()}
                    onChange={(event) => {
                      validate(21, input23Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Star />
                  <InputGame
                    ref={input23Ref}
                    success={word[22] === " " || word[22] === "" ? null : RESULT[22] === word[22].toLowerCase()}
                    onChange={(event) => {
                      validate(22, input24Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Airplane />
                  <InputGame
                    ref={input24Ref}
                    success={word[23] === " " || word[23] === "" ? null : RESULT[23] === word[23].toLowerCase()}
                    onChange={(event) => {
                      validate(23, input25Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Pencil />
                  <InputGame
                    ref={input25Ref}
                    success={word[24] === " " || word[24] === "" ? null : RESULT[24] === word[24].toLowerCase()}
                    onChange={(event) => {
                      validate(24, input26Ref, event);
                    }}
                  />
                </li>
              </ul>
            </div>

            <div>
              <ul>
                <li>
                  <Spades />
                  <InputGame
                    ref={input26Ref}
                    success={word[25] === " " || word[25] === "" ? null : RESULT[25] === word[25].toLowerCase()}
                    onChange={(event) => {
                      validate(25, input27Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Smile />
                  <InputGame
                    ref={input27Ref}
                    success={word[26] === " " || word[26] === "" ? null : RESULT[26] === word[26].toLowerCase()}
                    onChange={(event) => {
                      validate(26, input28Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Pencil />
                  <InputGame
                    ref={input28Ref}
                    success={word[27] === " " || word[27] === "" ? null : RESULT[27] === word[27].toLowerCase()}
                    onChange={(event) => {
                      validate(27, input29Ref, event);
                    }}
                  />
                </li>
              </ul>
              <ul>
                <li>
                  <Scissor />
                  <InputGame
                    ref={input29Ref}
                    success={word[28] === " " || word[28] === "" ? null : RESULT[28] === word[28].toLowerCase()}
                    onChange={(event) => {
                      validate(28, input30Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Smile />
                  <InputGame
                    ref={input30Ref}
                    success={word[29] === " " || word[29] === "" ? null : RESULT[29] === word[29].toLowerCase()}
                    onChange={(event) => {
                      validate(29, input31Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Home />
                  <InputGame
                    ref={input31Ref}
                    success={word[30] === " " || word[30] === "" ? null : RESULT[30] === word[30].toLowerCase()}
                    onChange={(event) => {
                      validate(30, input32Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Home />
                  <InputGame
                    ref={input32Ref}
                    success={word[31] === " " || word[31] === "" ? null : RESULT[31] === word[31].toLowerCase()}
                    onChange={(event) => {
                      validate(31, input33Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Radio />
                  <InputGame
                    ref={input33Ref}
                    success={word[32] === " " || word[32] === "" ? null : RESULT[32] === word[32].toLowerCase()}
                    onChange={(event) => {
                      validate(32, input34Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Star />
                  <InputGame
                    ref={input34Ref}
                    success={word[33] === " " || word[33] === "" ? null : RESULT[33] === word[33].toLowerCase()}
                    onChange={(event) => {
                      validate(33, input35Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Smile />
                  <InputGame
                    ref={input35Ref}
                    success={word[34] === " " || word[34] === "" ? null : RESULT[34] === word[34].toLowerCase()}
                    onChange={(event) => {
                      validate(34, input36Ref, event);
                    }}
                  />
                </li>
                <li>
                  <Pencil />
                  <InputGame
                    ref={input36Ref}
                    success={word[35] === " " || word[35] === "" ? null : RESULT[35] === word[35].toLowerCase()}
                    onChange={(event) => {
                      validate(35, input36Ref, event);
                    }}
                  />
                </li>
              </ul>
            </div>
          </S.List2>
          <Button onClick={() => setActive(!active)}>Como Jogar?</Button>
        </Content>
        {
          active === true &&
          <Modal open={active} setOpen={() => setActive(!active)} src={Video}/>
        }
        <CircularButton onClick={nextPage}><IoIosArrowForward /></CircularButton>
      </Container>
    </>
  );
}

export default Pageeight;