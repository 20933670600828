import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';

import Container from '../../components/Container';
import Header from '../../components/Header';
import Content from '../../components/Content';
import Paragraph from '../../components/Paragraph';
import Title from '../../components/Title';
import TextGreen from '../../components/TextGreen';
import CircularButton from '../../components/CircularButton';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import * as S from './styles';

import ilustra2 from '../../assets/03.png';

function Pagetwo() {
  const history = useHistory();
  const [modal, setModal] = useState(false);

  function backPage(){
    history.push("/1");
  }

  function nextPage(){
    history.push("/3");
  }

  function goGame(){
    history.push("/cacapalavra/1");
  }

  return (
    <>
      <Modal open={modal} setOpen={() => setModal(!modal)} >
        <Title style={{marginBottom: '20px'}}>Cadastro Único:</Title> Registro para identificar as famílias de baixa renda existentes no país.
      </Modal>
      <Container>
        <CircularButton onClick={backPage}><IoIosArrowBack /></CircularButton>
        <Content>
          <Header>CAÇA-PALAVRA</Header>
          <Paragraph italic>Procure e marque, no diagrama de letras, as palavras em destaque no texto.</Paragraph>
          <Title>Quem tem direito ao benefício?</Title>
          <S.Textimage>
            <img src={ilustra2} alt="Salão de cabelereiro" />
            <Paragraph>Para receber o <TextGreen>AUXÍLIO</TextGreen> mensal, a pessoa deve preencher todos os requisitos a seguir: ser maior de 18 anos; não ter um <TextGreen>EMPREGO</TextGreen> formal, com carteira assinada; não receber outro auxílio formal do Governo, como seguro-desemprego, <TextGreen>BENEFÍCIO</TextGreen> previdenciário ou assistencial; renda familiar mensal por pessoa de até meio <TextGreen>SALÁRIO</TextGreen> mínimo (R$ 522,50) ou renda familiar de até três salários mínimos (R$ 3.135,00); e não ter recebido rendimentos tributáveis, no ano de 2018, acima de RS 28.555,70.</Paragraph>
            <Paragraph>O benefício também pode ser adquirido pelo <TextGreen>CIDADÃO</TextGreen> que se encaixe em uma dessas condições: exerce atividade na <TextGreen>CONDIÇÃO</TextGreen> de microempreendedor individual(MEI); é contribuinte <TextGreen>INDIVIDUAL</TextGreen> ou facultativo do Regime GERAL de Previdência Social (RGPS). Contribuintes individuais ou facultativos do Regime Geral de Previdência e trabalhadores informais inscritos no <TextGreen style={{cursor: "pointer"}} onClick={() => setModal(true)}>CADASTRO</TextGreen> Único para Programas Sociais do Governo também têm <TextGreen>DIREITO</TextGreen> ao benefício.</Paragraph>
          </S.Textimage>
          <Button onClick={goGame}>Jogar!</Button>
        </Content>
        <CircularButton onClick={nextPage}><IoIosArrowForward /></CircularButton>
      </Container>
    </>
  );
}

export default Pagetwo;