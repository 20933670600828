import styled, { css } from 'styled-components';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: #fff;
  border: 0;
  outline: none;
  align-self: center;
  cursor: pointer;

  transition: filter .2s;

  :hover{
      filter: brightness(75%);
    }

  ${({last}) => last && css`
    margin-left: 10px;
  `}
`;
