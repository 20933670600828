import React, { useState, createRef/*, useEffect*/ } from 'react';
import { useHistory } from 'react-router-dom';

import Container from '../../components/Container';
import Header from '../../components/Header';
import Content from '../../components/Content';
import InputGame from '../../components/InputGame';
import Paragraph from '../../components/Paragraph';
import CircularButton from '../../components/CircularButton';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import Title from '../../components/Title';
import TextGreen from '../../components/TextGreen';

import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import Video from '../../assets/videos/sigaassetas.mp4';

import * as S from './styles';

import ilustra1 from '../../assets/05.png';

function Pagefive() {
  const history = useHistory();
  const [active, setActive] = useState(false);

  function pageBack() {
    history.push('/4');
  }

  function pageNext() {
    history.push('/6');
  }

  const input2Ref = createRef();
  const input3Ref = createRef();
  const input4Ref = createRef();
  const input5Ref = createRef();
  const input6Ref = createRef();
  const input7Ref = createRef();
  const input8Ref = createRef();
  const input9Ref = createRef();
  const input10Ref = createRef();
  const input11Ref = createRef();
  const input12Ref = createRef();

  const RESULT = "bolsafamilia";
  const [word, setWord] = useState("            ");
  const [modal, setModal] = useState(false);

  const [points, setPoints] = useState(0);
  // useEffect(() => {
  //   setPoints(sessionStorage.getItem('pointsUser'));
  // },[])
  
  // useEffect(() => {
  //   sessionStorage.setItem('pointsUser', points);
  // }, [points]);

  // const updatePoints = () => {
  //   let curr_points = sessionStorage.getItem('pointsUser')

  //   if(curr_points > 0){
  //     setPoints(curr_points - 20);
  //   }
  // }

  function validate(idx, ref, event) {
    if (event.target.value.length <= 0 || !ref.current) {
      return
    }
    setWord(word.substring(0, idx) + event.target.value + word.substring(idx + 1))
    ref.current.focus();

    // if(RESULT[idx] !== event.target.value.toLowerCase()){
    //   let curr_points = sessionStorage.getItem('pointsUser');

    //   if (curr_points >= 5) {
    //     setPoints(curr_points - 5);
    //   } else {
    //     setPoints(0);
    //     history.push('/');
    //   }
    // }
  }

  return (
    <>
      <Modal open={modal} setOpen={() => setModal(!modal)} >
        <Title style={{marginBottom: '20px'}}>Renda Familiar:</Title>O valor total recebido em dinheiro por toda a família.
      </Modal>
      <Container>
        <CircularButton onClick={pageBack}><IoIosArrowBack /></CircularButton>
        <Content>
          <Header chronometer points={points} /*onTimeout={() => updatePoints()}*/>SIGA A SETA</Header>
          <Paragraph>Para verificação da <TextGreen style={{cursor: "pointer"}} onClick={() => setModal(true)}>renda familiar</TextGreen>, o Governo irá considerar todos os rendimentos obtidos por todos aqueles que moram na mesma residência, exceto um benefício.</Paragraph>
          <Paragraph italic>Siga a seta e descubra que benefício é esse cujo rendimento não entra nessa verificação.</Paragraph>

          <S.Image><img src={ilustra1} alt="Ilustração 1" /></S.Image>

          <S.Game>
            <span>INÍCIO</span>
            <div className="circles">
              <div>
                <S.Circle className="first">B</S.Circle>
                <S.Left />
                <S.Circle>E</S.Circle>
                <S.Left />
                <S.Circle>D</S.Circle>
                <S.Right />
                <S.Circle>C</S.Circle>
                <S.Right />
                <S.Circle>O</S.Circle>
                <S.Right />
                <S.Circle>J</S.Circle>
                <S.Right />
                <S.Circle>O</S.Circle>
              </div>

              <div className="updown">
                <S.Down />
                <S.Down />
                <S.Down />
                <S.Down />
                <S.Up />
                <S.Down />
                <S.Down />
              </div>

              <div>
                <S.Circle>O</S.Circle>
                <S.Right />
                <S.Circle>L</S.Circle>
                <S.Left />
                <S.Circle>A</S.Circle>
                <S.Right />
                <S.Circle>S</S.Circle>
                <S.Right />
                <S.Circle>A</S.Circle>
                <S.Right />
                <S.Circle>I</S.Circle>
                <S.Right />
                <S.Circle>A</S.Circle>
              </div>

              <div className="updown">
                <S.Up />
                <S.Down />
                <S.Down />
                <S.Up />
                <S.Down />
                <S.Up />
                <S.Up />
              </div>

              <div>
                <S.Circle>A</S.Circle>
                <S.Right />
                <S.Circle>S</S.Circle>
                <S.Right />
                <S.Circle>A</S.Circle>
                <S.Left />
                <S.Circle>L</S.Circle>
                <S.Right />
                <S.Circle>I</S.Circle>
                <S.Right />
                <S.Circle>L</S.Circle>
                <S.Left />
                <S.Circle>Q</S.Circle>
              </div>

              <div className="updown">
                <S.Up />
                <S.Up />
                <S.Down />
                <S.Down />
                <S.Up />
                <S.Up />
                <S.Down />
              </div>

              <div>
                <S.Circle>N</S.Circle>
                <S.Left />
                <S.Circle>I</S.Circle>
                <S.Right />
                <S.Circle>F</S.Circle>
                <S.Right />
                <S.Circle>A</S.Circle>
                <S.Right />
                <S.Circle>M</S.Circle>
                <S.Left />
                <S.Circle>I</S.Circle>
                <S.Left />
                <S.Circle>U</S.Circle>
              </div>

              <div className="updown">
                <S.Down />
                <S.Up />
                <S.Up />
                <S.Up />
                <S.Up />
                <S.Down />
                <S.Up />
              </div>

              <div>
                <S.Circle>D</S.Circle>
                <S.Left />
                <S.Circle>O</S.Circle>
                <S.Right />
                <S.Circle>R</S.Circle>
                <S.Right />
                <S.Circle>I</S.Circle>
                <S.Right />
                <S.Circle>A</S.Circle>
                <S.Right />
                <S.Circle>M</S.Circle>
                <S.Left />
                <S.Circle>I</S.Circle>
              </div>
            </div>
          </S.Game>

          <S.Inputgame>
            <ul>
              <li>
                <InputGame
                  success={word[0] === " " || word[0] === "" ? null : RESULT[0] === word[0].toLowerCase()}
                  onChange={(event) => {
                    validate(0, input2Ref, event);
                  }}
                />
              </li>
              <li>
                <InputGame
                  ref={input2Ref}
                  success={word[1] === " " || word[1] === "" ? null : RESULT[1] === word[1].toLowerCase()}
                  onChange={(event) => {
                    validate(1, input3Ref, event);
                  }}
                />
              </li>
              <li>
                <InputGame
                  ref={input3Ref}
                  success={word[2] === " " || word[2] === "" ? null : RESULT[2] === word[2].toLowerCase()}
                  onChange={(event) => {
                    validate(2, input4Ref, event);
                  }}
                />
              </li>
              <li>
                <InputGame
                  ref={input4Ref}
                  success={word[3] === " " || word[3] === "" ? null : RESULT[3] === word[3].toLowerCase()}
                  onChange={(event) => {
                    validate(3, input5Ref, event);
                  }}
                />
              </li>
              <li>
                <InputGame
                  ref={input5Ref}
                  success={word[4] === " " || word[4] === "" ? null : RESULT[4] === word[4].toLowerCase()}
                  onChange={(event) => {
                    validate(4, input6Ref, event);
                  }}
                />
              </li>
            </ul>
            <ul>
              <li>
                <InputGame
                  ref={input6Ref}
                  success={word[5] === " " || word[5] === "" ? null : RESULT[5] === word[5].toLowerCase()}
                  onChange={(event) => {
                    validate(5, input7Ref, event);
                  }}
                />
              </li>
              <li>
                <InputGame
                  ref={input7Ref}
                  success={word[6] === " " || word[6] === "" ? null : RESULT[6] === word[6].toLowerCase()}
                  onChange={(event) => {
                    validate(6, input8Ref, event);
                  }}
                />
              </li>
              <li>
                <InputGame
                  ref={input8Ref}
                  success={word[7] === " " || word[7] === "" ? null : RESULT[7] === word[7].toLowerCase()}
                  onChange={(event) => {
                    validate(7, input9Ref, event);
                  }}
                />
              </li>
              <li>
                <InputGame
                  ref={input9Ref}
                  success={word[8] === " " || word[8] === "" ? null : RESULT[8] === word[8].toLowerCase()}
                  onChange={(event) => {
                    validate(8, input10Ref, event);
                  }}
                />
              </li>
              <li>
                <InputGame
                  ref={input10Ref}
                  success={word[9] === " " || word[9] === "" ? null : RESULT[9] === word[9].toLowerCase()}
                  onChange={(event) => {
                    validate(9, input11Ref, event);
                  }}
                />
              </li>
              <li>
                <InputGame
                  ref={input11Ref}
                  success={word[10] === " " || word[10] === "" ? null : RESULT[10] === word[10].toLowerCase()}
                  onChange={(event) => {
                    validate(10, input12Ref, event);
                  }}
                />
              </li>
              <li>
                <InputGame
                  ref={input12Ref}
                  success={word[11] === " " || word[11] === "" ? null : RESULT[11] === word[11].toLowerCase()}
                  onChange={(event) => {
                    validate(11, input12Ref, event);
                  }}
                />
              </li>
            </ul>
          </S.Inputgame>
          <Button onClick={() => setActive(!active)}>Como Jogar?</Button>
        </Content>
        {
          active === true &&
          <Modal open={active} setOpen={() => setActive(!active)} src={Video}/>
        }
        <CircularButton onClick={pageNext}><IoIosArrowForward /></CircularButton>
      </Container>
    </>
  );
}

export default Pagefive;