import styled from 'styled-components';

export const Paragraphs = styled.p`
    line-height: 1.5;
    font-size: 14px;
    font-weight: 300;
    text-align: justify;
    text-indent: 2em;
    margin: 0 auto 5px;
    color: ${({colors}) => colors ? `${({theme}) => theme.palette.secondary.main}` : '#000'};
    font-style: ${({italic}) => italic ? 'italic' : 'bold'};
    width: 95%;
    &:last-child {
        margin-bottom: 0;
    }
`
