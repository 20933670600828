import React from 'react';
import {useHistory} from 'react-router-dom';

import * as S from './styles';

import Container from '../../components/Container';

import Content from '../../components/Content';
import CircularButton from '../../components/CircularButton';

import { 
  IoIosArrowBack,
  IoIosArrowForward
} from 'react-icons/io';

// import ilustra1 from '../../assets/01.png';

function Capa() {
  const history = useHistory();

  function nextPage(){
    history.push('/1');
  }

  // sessionStorage.setItem('pointsUser', 100);

  return (
      <Container>
        <CircularButton disabled><IoIosArrowBack /></CircularButton>
        <Content>
          <S.ColorBg />
        </Content>
        <CircularButton onClick={nextPage}><IoIosArrowForward /></CircularButton>
      </Container>
  );
}

export default Capa;