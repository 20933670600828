import styled from 'styled-components';
import {BsClockHistory} from 'react-icons/bs';

export const Container = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
  min-width: 50px;
  height: 25px;
  border-radius: 5%;
  background-color: #fff;
  p.normal{
    color: ${({theme}) => theme.palette.secondary.main};
  }
  p.red {
    color: red;
  }
`;

export const BsClockHistorys = styled(BsClockHistory)`
  margin-top: 1px;
  margin-right: 5px;
`